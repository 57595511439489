import React, { useCallback } from 'react'
import z from 'zod'
import { subscriptionSection } from '@/schemas'
import { DataSourceType, useData } from '@/hooks/useData'
import { SimpleForm } from '@heights/heights-ui-library'
import type { SingleComponentPickerItem } from '@/components/single-component-picker/SingleComponentPicker'
import { CheckIcon } from '@heights/heights-icons'
import { klaviyoSubscribeHandlerFactory } from '@/utils'

type Props = {
  data: SingleComponentPickerItem
}

const [query, type] = subscriptionSection.builder
  .filter('_id == $id')
  .first()
  .use()

const schema = z.object({
  email: z.string().email(),
})

export const PdpHeroWrapperSubscriptionSection = ({ data }: Props) => {
  const reader = useData<typeof type>(DataSourceType.CONTENTFUL, query, {
    id: data._id,
  })
  const section = reader()

  const handleSubmit = useCallback(
    async (values: z.infer<typeof schema>) => {
      const listId = section?.fields?.listId
      if (listId) {
        try {
          const response = await klaviyoSubscribeHandlerFactory(listId)([
            {
              email: values.email,
              $consent: 'email',
            },
          ])

          if (!response.success) {
            throw new Error('Unable to subscribe to wait list')
          }
        } catch (err) {
          console.error(err)
          throw new Error('Unable to subscribe to wait list')
        }
      }
    },
    [section?.fields?.listId]
  )

  if (!section?.fields) {
    return null
  }

  const {
    buttonText = 'Sign up to the wait list',
    placeholderText,
    successTitle = "You're on  the list!",
  } = section.fields

  return (
    <SimpleForm
      validation={schema}
      buttonConfig={{
        label: buttonText,
      }}
      successConfig={{
        type: 'cta',
        content: successTitle,
        icon: <CheckIcon size="1.25rem" />,
      }}
      fields={[
        {
          type: 'email',
          name: 'email',
          label: 'Email address *',
          placeholder: placeholderText,
        },
      ]}
      onSubmit={handleSubmit}
    />
  )
}
