import React, { useCallback, useMemo, useState, useEffect } from 'react'
import { useProduct } from '@/hooks/useProduct'
import {
  useCtaAction,
  useCurrency,
  useData,
  useDatahappy,
  useOkendoData,
  useUrl,
} from '@/hooks'
import { ProductVariantModel } from '@/types/ProductVariantModel'
import {
  ChargeIntervalLabels,
  ChargeIntervalSuffixes,
  IntervalPlanLabels,
} from '@/constants'
import {
  SimpleProductHero,
  SimpleProductHeroProps,
} from '@heights/heights-ui-components'
import { RichTextContent } from '@/components/RichTextContent'
import { CheckoutButton } from '../CheckoutButton'
import { transformImage } from '@/utils/contentful'
import compact from 'just-compact'
import { SingleComponentPickerItem } from '@/components/single-component-picker/SingleComponentPicker'
import { pdpHero } from '@/schemas/pdpHero'
import { DataSourceType } from '@/hooks/useData'
import { ImageAttrs } from '@heights/heights-ui-library'
import { PdpHeroWrapperComponents } from './PdpHeroWrapperComponents'
import { VariantIdType } from '@/types'
import { decodeShopifyId } from '@/utils/shopify'
import { formatVariantSubtitle } from '@/utils/formatVariantSubtitle'
import { ComponentVariant } from '@heights/heights-constants'
import { formatVariantDescription } from '@/utils'

export interface PdpHeroWrapperProps {
  cmsData: SingleComponentPickerItem
}

const [query, type] = pdpHero.builder
  .filter('_id == $id')
  .map((hero) => ({
    product: hero.fields.product?.resolve(['_id', 'fields']).use(),
    media: hero.fields.media?.resolveIn('fields').use(),
    ctaAction: hero.fields.ctaAction?.resolve(['_id', 'fields']).use(),
    moreActions: hero.fields.moreActions?.resolveIn(['_id', 'fields']).use(),
  }))
  .first()
  .use()

export const PdpHeroWrapperCurrent: React.FC<PdpHeroWrapperProps> = ({
  cmsData,
}) => {
  const url = useUrl()
  const [datahappy] = useDatahappy()
  const reader = useData<typeof type>(DataSourceType.CONTENTFUL, query, {
    id: cmsData._id,
  })
  const pdpHero = reader()
  const moreActions = useCtaAction(pdpHero?.moreActions)

  const shopifyProductId = pdpHero?.product?.fields?.shopifyProductId

  const safeProductId = shopifyProductId
    ? decodeShopifyId(shopifyProductId)
    : null

  const okendoInfo = useOkendoData(safeProductId ?? 'store')
  const { currencyCode, currencySymbol, locale } = useCurrency()
  const productId = useMemo(
    () =>
      pdpHero?.product?.fields?.productId ?? pdpHero?.fields?.productId ?? -1,
    [pdpHero]
  )
  const product = useProduct(productId)

  const [selectedVariantId, setSelectedVariantId] =
    useState<VariantIdType | null>(product?.defaultVariantId ?? null)
  const selectedVariantIndex = useMemo(
    () =>
      product?.variants.findIndex(
        ({ variantId }) => variantId === selectedVariantId
      ) ?? -1,
    [product, selectedVariantId]
  )
  const productImages = useMemo(() => {
    if (pdpHero?.media?.length) {
      return compact(
        pdpHero.media.map((image) => ({
          ...transformImage(image, {
            width: 800,
            aspectRatio: 1,
            transformOptions: {
              fit: 'fill',
              focus: 'center',
            },
          }),
          vimeoSrc:
            image?.description &&
            image?.description.includes('player.vimeo.com')
              ? image.description
              : undefined,
        }))
      )
    }

    return []
  }, [pdpHero])

  const productVariantEntries = useMemo<
    SimpleProductHeroProps<
      ProductVariantModel,
      ComponentVariant.CURRENT
    >['entries']
  >(() => {
    return (
      product?.variants.map((variant) => ({
        productVariant: variant,
        title:
          variant.highlightLabel ??
          (variant.isSubscription
            ? IntervalPlanLabels[variant.chargeIntervalType]
            : ''),
        label: compact([variant.promoLabel, variant.saving]).join(' - '),
        priceInfo: {
          prefix: currencySymbol,
          price: variant.costPerChargeFrequency[currencyCode],
          suffix:
            variant.isSubscription && variant.chargeIntervalType
              ? ChargeIntervalSuffixes[variant.chargeIntervalType]
              : undefined,
        },
        comparePriceInfo: variant.compareCostPerChargeFrequency[currencyCode]
          ? {
              prefix: currencySymbol,
              price: variant.compareCostPerChargeFrequency[currencyCode],
            }
          : undefined,
        description:
          formatVariantDescription(variant, locale, currencyCode) ||
          formatVariantSubtitle(variant, locale, currencyCode) ||
          (variant.isSubscription
            ? ChargeIntervalLabels[
                variant.chargeIntervalType as keyof typeof ChargeIntervalLabels
              ]
            : undefined),
        details: variant.additionalInformation,
      })) ?? []
    )
  }, [locale, currencySymbol, currencyCode, product?.variants])

  const priceInfo = useMemo(() => {
    const variant =
      product?.variants?.[selectedVariantIndex] ?? product?.variants?.[0]
    if (variant) {
      return {
        price: variant.costPerChargeFrequency[currencyCode],
        totalPrice: variant.costPerChargeFrequency[currencyCode],
        priceLabel:
          variant.isSubscription && variant.chargeIntervalType
            ? ChargeIntervalLabels[variant.chargeIntervalType]
            : '',
      }
    }
    return null
  }, [product, selectedVariantIndex, currencyCode])

  const handleSelectVariant = useCallback((variant: ProductVariantModel) => {
    setSelectedVariantId(variant.variantId)
  }, [])

  const productBenefits = useMemo(
    () =>
      pdpHero?.fields?.productBenefits?.map((benefit) => ({ label: benefit })),
    [pdpHero?.fields]
  )

  const variantInfo = useMemo(() => {
    if (!productVariantEntries) return
    return productVariantEntries[selectedVariantIndex]
  }, [productVariantEntries, selectedVariantIndex])

  const eventInfo = useMemo<(string | number | null)[]>(() => {
    const productId =
      (product?.shopifyProductId &&
        decodeShopifyId(product?.shopifyProductId)) ??
      undefined
    const variantId =
      (selectedVariantId && decodeShopifyId(selectedVariantId)) ?? undefined
    return [productId ?? null, variantId ?? null]
  }, [product, selectedVariantId])

  useEffect(() => {
    // to prevent too many events from being sent
    // we'll only run this effect when the productId or variant changes

    const productName = product?.name
    const variantName = variantInfo?.title
    const name = `${productName} - ${variantName}`
    const price = priceInfo?.totalPrice

    const ecommerce = {
      currencyCode,
      value: price,
      detail: {
        products: [
          {
            id: eventInfo[0] ?? undefined,
            productId: eventInfo[0] ?? undefined,
            variantId: eventInfo[1] ?? undefined,
            productName,
            variantName,
            price,
            name,
          },
        ],
      },
    }

    datahappy.trackViewItem(ecommerce)
  }, [eventInfo[0], eventInfo[1]])

  useEffect(() => {
    if (url?.searchParams) {
      const variant = url.searchParams.get('variant')
      if (variant) {
        const variantId = Number(variant)
        const isValid = productVariantEntries?.find(
          (variant) =>
            decodeShopifyId(variant.productVariant.variantId) === variantId
        )
        if (isValid) {
          setSelectedVariantId(isValid.productVariant.variantId)
        }
      }
    }
  }, [url])

  if (!product) {
    return null
  }

  return (
    <SimpleProductHero
      variant={ComponentVariant.CURRENT}
      title={pdpHero?.fields?.name || product?.name}
      maxQuantity={50}
      moreActions={moreActions ?? []}
      starReview={
        pdpHero?.fields?.showTrustpilot
          ? {
              numberOfReviews: okendoInfo?.totalNumberOfReviews ?? 0,
              rating: okendoInfo?.stars ?? 0,
            }
          : undefined
      }
      description={
        !!pdpHero?.fields?.description && (
          <RichTextContent
            inheritParagraphFont
            removeFirstHeadingMargin
            document={pdpHero?.fields.description}
          />
        )
      }
      images={productImages as ImageAttrs[]} // todo: need to look into why
      entries={productVariantEntries}
      selectedIndex={selectedVariantIndex}
      onSelectVariant={handleSelectVariant}
      renderCheckoutForm={
        pdpHero?.fields?.components?.length
          ? () => (
              <div className="mt-2 md:mt-4">
                <PdpHeroWrapperComponents
                  components={pdpHero.fields?.components}
                />
              </div>
            )
          : undefined
      }
      renderCheckoutButton={
        <CheckoutButton
          ctaAction={pdpHero?.ctaAction}
          productId={product.productId}
          variantId={selectedVariantId}
          className="mt-0 mb-0"
        />
      }
      benefits={productBenefits}
      servingInfos={pdpHero?.fields?.servingInfos}
    />
  )
}
