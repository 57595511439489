import React from 'react'
import dynamic from 'next/dynamic'
import { pdpHero } from '@/schemas'
import { PdpHeroWrapperSubscriptionSection } from './PdpHeroWrapperSubscriptionSection'

const [, type] = pdpHero.builder
  .first()
  .map((hero) => ({
    components: hero.fields.components?.use(),
  }))
  .pick('components')
  .use()

type Props = {
  components: typeof type
}

const DynamicComponentPicker = dynamic(
  () => import('../../../../ComponentPicker/ComponentPicker')
)

export function PdpHeroWrapperComponents({ components }: Props) {
  return (
    <DynamicComponentPicker
      data={components}
      overrides={{
        subscriptionSection: PdpHeroWrapperSubscriptionSection,
      }}
    />
  )
}
