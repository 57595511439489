import { useEffect, useState } from 'react'

export default function useUrl() {
  const [url, setUrl] = useState<URL | null>(null)

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setUrl(new URL(window.location.href))
    }
  }, [])

  return url
}
