import React, { FC, useCallback, useMemo } from 'react'
import { CallToAction } from '@heights/heights-ui-library'
import { uid } from '@heights/heights-utils'
import { useIntl } from '@heights/heights-context'
import { useHandleCheckout } from '@/hooks/useHandleCheckout'
import { useCtaAction } from '@/hooks/useCtaAction'
import { PromiseStatus, usePromiseLoader } from '@/hooks/usePromiseLoader'
import { useProduct } from '@/hooks/useProduct'
import { VariantIdType } from '@/types/VariantIdType'
import { ctaAction } from '@/schemas'
import { twMerge } from '@heights/heights-theme'

interface CheckoutButtonProps {
  productId: number
  variantId?: VariantIdType | null
  quantity?: number
  ctaAction?: Pick<typeof ctaAction.ctaAction, 'fields'>
  className?: string
}

export const CheckoutButton: FC<CheckoutButtonProps> = ({
  productId,
  variantId,
  quantity,
  ctaAction,
  className,
}) => {
  const cta = useCtaAction(ctaAction)
  const product = useProduct(productId)
  const variant = useMemo(() => {
    const variant =
      product?.variants?.find((v) => v.variantId === variantId) ?? null
    return variant
  }, [product, variantId])
  const { locale, defaultLocale } = useIntl()

  const {
    load: handleCheckout,
    status: checkoutStatus,
    error: checkoutError,
  } = usePromiseLoader(useHandleCheckout(locale ?? defaultLocale))

  //@README we may reintroduce this at some point
  // const formattedPrice = useMemo(() => {
  //   if (!variant) return null

  //   const chargeIntervalQuantity = variant.chargeIntervalType
  //     ? ChargeIntervalQuantity[variant.chargeIntervalType]
  //     : 1

  //   const total =
  //     (variant.costPerIntervalFrequency?.[currencyCode] ?? 0) *
  //     chargeIntervalQuantity *
  //     (quantity ?? 0)
  //   return formatPrice(total, locale, {
  //     currency: currencyCode || 'GBP',
  //   })
  // }, [locale, currencyCode, variant, quantity])

  const handlePdpCtaClick = useCallback(() => {
    if (!variant) return
    handleCheckout([
      {
        productId,
        quantity: quantity || 1,
        variantId: variant.variantId,
      },
    ])
  }, [productId, variant, quantity, handleCheckout])

  const buttonCopy = useMemo(() => {
    if (checkoutStatus === PromiseStatus.PENDING) {
      return 'Adding to cart...'
    }

    const addToCartLabel = 'Add to cart'
    return addToCartLabel
  }, [checkoutStatus])

  return (
    <>
      <CallToAction
        as="button"
        cta={{
          ...(cta ?? {}),
          onClick: handlePdpCtaClick,
        }}
        defaultCtaStyle="primary"
        defaultCtaSize="large"
        className={twMerge('w-full mt-3 mb-2 px-4', className)}
        data-track-id={uid(`pdp - hero - order - ${productId} `)}
        disabled={!variantId || checkoutStatus === PromiseStatus.PENDING}
      >
        {buttonCopy}
      </CallToAction>
      {checkoutStatus === PromiseStatus.ERROR && checkoutError && (
        <div className="text-error text-2 my-2 text-center" aria-live="polite">
          {checkoutError.message}
        </div>
      )}
    </>
  )
}
