import React, { FC, useContext, useRef } from 'react'
import { useScroll, useMotionValueEvent } from 'framer-motion'
import { SingleComponentPickerItem } from '../../SingleComponentPicker'
import { PdpHeroWrapperCurrent } from './variants/PdpHeroWrapperCurrent'
import { PrepassContext } from '@/context'

export type ProductTypes = 'subscription' | 'preOrder' | 'gift'

export interface PdpHeroWrapperProps {
  cmsData: SingleComponentPickerItem
}

const PdpHeroWrapper: FC<PdpHeroWrapperProps> = ({ cmsData }) => {
  const { isPrepass } = useContext(PrepassContext)
  const containerRef = useRef<HTMLDivElement | null>(null)

  if (!isPrepass) {
    // eslint-disable-next-line
    const { scrollYProgress } = useScroll({
      target: containerRef,
      offset: ['start end', 'end center'],
    } as Parameters<typeof useScroll>[0])

    // eslint-disable-next-line
    useMotionValueEvent(scrollYProgress, 'change', (value) => {
      document.body.dataset.hideZowieMobile = String(
        Number(value > 0 && value < 1)
      )
    })
  }

  return (
    <div ref={containerRef}>
      <PdpHeroWrapperCurrent cmsData={cmsData} />
    </div>
  )
}

export default PdpHeroWrapper
