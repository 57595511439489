import React from 'react'
import { ProductVariantModel } from '@/types'
import { formatVariantFormattedString } from './formatVariantFormattedString'
import Markdown from 'react-markdown'

export function formatVariantDescription(
  variant: ProductVariantModel,
  locale: string,
  currencyCode: string
) {
  const description = variant.description
  if (!description) return null
  const formatted = formatVariantFormattedString(
    description,
    variant,
    locale,
    currencyCode
  )
  return <Markdown>{formatted}</Markdown>
}
